<template>
    <form
        id="revinate_contact_api_form"
        :token="token"
        autocomplete="off"
        class="newsletter-form"
        @submit.prevent="submitForm"
    >
        <BaseFormField>
            <BaseInput
                v-model="name"
                type="text"
                name="firstName"
                required
                :label="$t('your-name')"
                :novalidate="false"
            />
        </BaseFormField>

        <BaseFormField>
            <BaseInput
                v-model="email"
                type="email"
                name="email"
                :label="$t('your-email')"
                required
                :novalidate="false"
            />
        </BaseFormField>

        <BaseFormField>
            <BaseCheckbox
                v-model="agreed"
                required
                :novalidate="false"
            >
                <i18n-t
                    keypath="i-agree"
                    for="toc"
                >
                    <NuxtLink
                        :to="localePath('/terms')"
                        target="_blank"
                    >
                        {{ $t('toc') }}
                    </NuxtLink>
                </i18n-t>
            </BaseCheckbox>
        </BaseFormField>

        <BaseFormField class="newsletter-form__action">
            <BaseButton
                class="base-button--tertiary"
                type="submit"
                :disabled="submitted"
            >
                <template #default>
                    <template v-if="!submitted">
                        {{ $t('subscribe') }}
                    </template>
                    <template v-else>
                        {{ $t('subscribed') }}
                    </template>

                    <BaseIcon
                        v-show="submitted"
                        icon="check"
                    />
                </template>
            </BaseButton>
        </BaseFormField>
    </form>
</template>

<script setup>
import { useGtm } from '@gtm-support/vue-gtm';

const email = ref(null);
const name = ref(null);
const agreed = ref(false);

defineProps({
    token: {
        type: String,
        default: '1f744e41-1103-4201-8428-1149e6cf216f'
    }
});

useHead({
    script: [
        {
            src: '//contact-api.inguest.com/bundles/revinatecontactapi/js/revinate-form.js?v=1',
            async: true,
            defer: true,
            fetchpriority: 'low',
            tagPriority: 'low',
            tagPosition: 'bodyClose'
        }
    ]
});

onMounted(() => {
    email.value = null;
    name.value = null;
    agreed.value = false;
});

const localePath = useLocalePath();

const submitted = ref(false);
function submitForm(e) {
    revFormOnSubmit(e);
    submitted.value = true;

    const gtm = useGtm();
    gtm.trackEvent({
        /* eslint-disable camelcase */
        event: 'newsletter_signup',
        /* eslint-enable camelcase */
    });
}
</script>

<style lang="less">
.newsletter-form .base-form-field.newsletter-form__action {
    margin-top: var(--spacing-xl);
}
</style>
